import * as React from "react"
import "./index.css"
import Top from "../sections/sim/top/top"
import Steps from "../sections/sim/steps/steps"

import Seo from "../components/seo/seo"
import Testimonial from "../sections/sim/testimonial/testimonial"
import CTA from "../sections/sim/cta/cta"
import FAQ from "../sections/sim/faq/faq"
import Footer from "../sections/sim/footer/footer"

const Sim = () => (
  <div>
    <Seo title="Få styr på dit vedligehold" />
    <Top />
    <Steps />
    <Testimonial />
    <CTA />
    <FAQ />
    <Footer />
  </div>
)

export default Sim
