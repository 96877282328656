// If you don't want to use TypeScript you can delete this file!
import * as React from "react"

import "./testimonial.css"

const Testimonial = () => (
  <div className="testimonial-sim__outer">
    <div className="testimonial-sim__wrapper" style={{ marginBottom: "60px" }}>
      <hr />
      <h3 className="testimonial-sim__quote">
        "Implementeringen er gået smertefrit, og operatører og værkstedsfolk har
        taget programmet til sig med det samme"
      </h3>
      <label htmlFor="" className="label testimonial-sim__quote-author">
        - Flemming Kristiansen, Kraftvarme/Automationschef
        <br />
        hos Gartneriet Rosborg Bellinge A/S i Odense{" "}
        <span style={{ fontSize: "var(--size-2)" }}>🇩🇰</span>
      </label>
    </div>

    <div className="testimonial-sim__wrapper">
      <h3 className="testimonial-sim__quote">
        "Et genialt system, som automatiserer vores vedligehold, og giver
        overblik"
      </h3>
      <label htmlFor="" className="label testimonial-sim__quote-author">
        - Torben Nielsen, indehaver af TN Værktøjsslibning i Bjæverskov{" "}
        <span style={{ fontSize: "var(--size-2)" }}>🇩🇰</span>
      </label>
      <hr />
    </div>
  </div>
)

export default Testimonial
