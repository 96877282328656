// If you don't want to use TypeScript you can delete this file!
import * as React from "react"

import "./cta.css"

const CTA = () => (
  <div className="cta-sim__outer">
    <div className="cta-sim__wrapper">
      <a href="https://www.onlion.io/auth/signup" className="button big">
        Prøv OnLion gratis nu
      </a>
    </div>
  </div>
)

export default CTA
