import * as React from "react"
import TextLoop from "react-text-loop"

import mockup from "./mockup.png"
import logo from "./logo.png"
import "./top.css"

const Top = () => {
  const [email, setEmail] = React.useState("")

  return (
    <div className="top-sim__outer">
      <div className="top-sim__logo-wrapper">
        <img src={logo} alt="OnLions logo" className="top-sim__logo" /> OnLion
      </div>
      <div className="top-sim__outer-bg">
        <div className={"top-sim__wrapper"}>
          <h1 className="top-sim__hero-heading">Få styr på dit vedligehold</h1>
          <p className="top-sim__hero-subtitle">
            OnLion har hjulpet virksomheder som din med at udføre over{" "}
            <b>9.000 </b>
            <TextLoop>
              <span>vedligehold</span>
              <span>eftersyn</span>
              <span>olieskift</span>
              <span>rengøringer</span>
              <span>sikkerhedscheck</span>
              <span>kontroller</span>
            </TextLoop>
          </p>
          <form
            onSubmit={e => e.preventDefault()}
            className="top-sim__hero-form"
          >
            <label htmlFor="email" className="label">
              email-adresse
            </label>
            <div className="group row row-mobile-col">
              <input
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="input top-sim__input"
                type="text"
                placeholder="Skriv din email-adresse her"
              />
              <a
                href={`https://www.onlion.io/auth/signup/${email}`}
                className="button big top-sim__button"
              >
                Prøv gratis nu
              </a>
            </div>
            <p className="top-sim__help">
              Prøv OnLion med op til 10 stykker udstyr, <u>gratis</u>, uden
              tidsbegrænsning. Email adresse bruges ved log ind. Vi sender
              aldrig spam, eller deler din email adresse.
            </p>
          </form>
        </div>
      </div>
      <div className="top-sim__wave">
        <img className="top-sim__mockup" src={mockup} alt="mockup" />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            className="top-sim__wave-svg"
            fill="#1a1f36"
            fill-opacity="1"
            d="M0,256L80,261.3C160,267,320,277,480,245.3C640,213,800,139,960,138.7C1120,139,1280,213,1360,250.7L1440,288L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
          ></path>
        </svg>
      </div>
    </div>
  )
}

export default Top
