// If you don't want to use TypeScript you can delete this file!
import * as React from "react"

import "./footer.css"
import logo from "./logo.png"

const Footer = () => (
  <div className="footer-sim__outer">
    <div className="footer-sim__wrapper">
      <div className="footer-sim__logo-wrapper">
        <img src={logo} alt="OnLions logo" className="footer-sim__logo" />{" "}
        OnLion
      </div>
    </div>
  </div>
)

export default Footer
