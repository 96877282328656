// If you don't want to use TypeScript you can delete this file!
import * as React from "react"

import "./faq.css"
import SingleFAQ from "./singleFaq"

const FAQ = () => {
  const [opened, _setOpened] = React.useState({
    1: false,
    2: false,
    3: false,
    4: false,
  })

  const toggleFAQ = number =>
    _setOpened({ ...opened, [number]: !opened[number] })

  return (
    <div className="faq-sim__outer">
      <div className="faq-sim__wrapper">
        <div className="group row">
          <h3>Ofte stillede spørgsmål</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="faq-sim__header-icon icon-information"
          >
            <path
              className="faq-sim__icon-primary"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
            />
            <path
              className="faq-sim__icon-secondary"
              d="M11 12a1 1 0 0 1 0-2h2a1 1 0 0 1 .96 1.27L12.33 17H13a1 1 0 0 1 0 2h-2a1 1 0 0 1-.96-1.27L11.67 12H11zm2-4a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
            />
          </svg>
        </div>

        <div className="faq-sim__faqs">
          {/* FAQ 1 */}
          <div className="faq-sim__single-faq">
            <div
              className="faq-sim__single-faq-button"
              onClick={() => toggleFAQ(1)}
            >
              <div className="group row space-between">
                <span>Hvad er OnLion, og hvordan virker det?</span>

                {!opened[1] && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="faq-sim__accordion-icon icon-cheveron-up-circle"
                  >
                    <circle cx="12" cy="12" r="10" className="primary" />
                    <path
                      className="secondary"
                      d="M8.7 13.7a1 1 0 1 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 10.42l-3.3 3.3z"
                    />
                  </svg>
                )}

                {opened[1] && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="faq-sim__accordion-icon icon-cheveron-up-circle"
                  >
                    <circle cx="12" cy="12" r="10" className="primary" />
                    <path
                      className="secondary"
                      d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div
              className={`faq-sim__single-faq-content ${
                opened[1] && "faq-sim__single-faq-content-show"
              }`}
            >
              <label htmlFor="" className="label">
                Det kan OnLion
              </label>
              <p>
                OnLion er et cloud-baseret program til styring af dit planlagte
                vedligehold. Programmet bruges direkte fra browseren på din
                computer, og er derfor altid tilgængeligt, uanset hvor du er. Da
                OnLion er cloud-baseret behøver du heller ikke selv at rode med
                installering, backup, opdateringer, og så videre - alt det
                klarer vi.
              </p>
              <p>For at komme i gang med programmet skal du:</p>
              <ol>
                <li>1. Oprette en gratis konto,</li>
                <li>2. Tilføje dine vedligeholds-ansvarlige,</li>
                <li>3. Tilføje det udstyr som skal vedligeholdes,</li>
                <li>4. Tilføje dine planlagte vedligeholdsopgaver</li>
              </ol>
              <p>
                (Vi går op i brugervenlighed, og du vil blive guided igennem
                disse trin når du opretter en gratis konto)
              </p>
              <p>
                Når det er gjort kan du læne dig tilbage og lade os om resten.
                OnLion sender automatisk opgaver til dem der skal udføre dem,
                når de skal udføres. Samtidig holder OnLion styr på hvad der
                bliver udført, og hvornår. Den data kan du til en hver tid se,
                og vi sender dig også en rapport i slutningen af hver måned.
              </p>

              <hr />
              <label htmlFor="" className="label">
                Bonus: Ubegrænset antal administratorer
              </label>
              <p>
                Du kan invitere andre i din virksomhed (eller en konsulent) til
                at administrere OnLion på dine vegne. For eksempel kan dem som
                er ansvarlige for vedligehold i en afdeling blive tilføjet til
                OnLion, og oprette deres eget udstyr, medarbejdere og
                vedligehold.
              </p>

              <hr />
              <label htmlFor="" className="label">
                Bonus: Noter
              </label>
              <p>
                Både du og ansvarlige kan oprette noter på jeres udstyr. Noter
                kan indeholder filer, såsom billeder eller .pdf'er. Noter kan
                f.eks. tilføjes ved udført service fra en ekstern leverandør,
                med en servicerapport vedlagt - så er dokumentationen helt på
                plads. Ansvarlige kan også dokumentere fejl og mangler. For
                eksempel, kan de notere en mislyd, og vælge en modtager på
                noten, så fejlen kan blive udbedret.
              </p>

              <hr />
              <label htmlFor="" className="label">
                Bonus: Medarbejder kvalifikationer
              </label>
              <p>
                Udover vedligehold af dit udstyr kan du også vedligeholde dine
                medarbejdere; har de bestemte kvalifikationer/kurser som
                udløber? Tilføj dem i OnLion - så modtager de en varsel når de
                skal genopfriskes.
              </p>
            </div>
          </div>

          {/* FAQ 2 */}
          <div className="faq-sim__single-faq">
            <div
              className="faq-sim__single-faq-button"
              onClick={() => toggleFAQ(2)}
            >
              <div className="group row space-between">
                <span>Hvad koster OnLion?</span>

                {!opened[2] && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="faq-sim__accordion-icon icon-cheveron-up-circle"
                  >
                    <circle cx="12" cy="12" r="10" className="primary" />
                    <path
                      className="secondary"
                      d="M8.7 13.7a1 1 0 1 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 10.42l-3.3 3.3z"
                    />
                  </svg>
                )}

                {opened[2] && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="faq-sim__accordion-icon icon-cheveron-up-circle"
                  >
                    <circle cx="12" cy="12" r="10" className="primary" />
                    <path
                      className="secondary"
                      d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div
              className={`faq-sim__single-faq-content ${
                opened[2] && "faq-sim__single-faq-content-show"
              }`}
            >
              <label htmlFor="" className="label">
                Gratis abonnement
              </label>
              <p>
                Det er gratis at bruge OnLion, med op til 10 stykker udstyr. Der
                er ingen tidsbegrænsning, og du kan derfor bruge OnLion (med op
                til 10 stykker udstyr) så længe du vil, inden du beslutter dig
                om det er den rigtige løsning for dig og dit vedligehold.
              </p>
              <p>Med gratis abonnement får du:</p>
              <ol>
                <li>- Fri brug af OnLion, med op til 10 stykker udstyr</li>
                <li>- Fri support (per telefon eller email)</li>
                <li>
                  - Ubegrænset upload af dokumenter på udstyr (manualer, med
                  mere)
                </li>
                <li>- Ubegrænset antal opgaver, på dine 10 stykker udstyr</li>
                <li>- Ubegrænset antal medarbejdere</li>
                <li>
                  - Ubegrænset antal medarbejderkvalifikationer (hold styr på
                  dine medarbejderes kvalifikationer, såsom svejsecertfikater,
                  hygienekurser, med mere)
                </li>
              </ol>

              <hr />
              <label htmlFor="" className="label">
                Ubegrænset abonnement
              </label>
              <p>
                Når du har besluttet dig for at OnLion er den bedste løsning for
                dig, koster det kun 349 kr./måned at oprette et ubegrænset antal
                udstyr. Ingen skjule gebyrer, bare én lav og simpel pris for
                alle.
              </p>
            </div>
          </div>

          {/* FAQ 3 */}
          <div className="faq-sim__single-faq">
            <div
              className="faq-sim__single-faq-button"
              onClick={() => toggleFAQ(3)}
            >
              <div className="group row space-between">
                <span>Hvad med support? Og kan jeg selv bruge OnLion?</span>

                {!opened[3] && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="faq-sim__accordion-icon icon-cheveron-up-circle"
                  >
                    <circle cx="12" cy="12" r="10" className="primary" />
                    <path
                      className="secondary"
                      d="M8.7 13.7a1 1 0 1 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 10.42l-3.3 3.3z"
                    />
                  </svg>
                )}

                {opened[3] && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="faq-sim__accordion-icon icon-cheveron-up-circle"
                  >
                    <circle cx="12" cy="12" r="10" className="primary" />
                    <path
                      className="secondary"
                      d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div
              className={`faq-sim__single-faq-content ${
                opened[3] && "faq-sim__single-faq-content-show"
              }`}
            >
              <label htmlFor="" className="label">
                Fri support for alle over email eller telefon
              </label>
              <p>
                Vi står klar til at hjælpe dig, uanset om du har et gratis eller
                ubegrænset abonnement. Support foregår over email, eller
                telefon, afhængigt af hvad der passer dig bedst.
              </p>
              <p>
                Tøv ikke med at kontakte os på{" "}
                <a href="mailto:kontakt@onlion.io">kontakt@onlion.io</a> hvis du
                har nogen spørgsmål, eller har brug for hjælp.
              </p>

              <hr />
              <label htmlFor="" className="label">
                Onlion er bygget til at være nemt
              </label>
              <p>
                OnLion er nemt at bruge. Det har været fokus fra starten, at
                lave et program til vedligehold som alle kan finde ud af - uden
                at skulle på kurser eller hyre dyre konsulenter først. Derfor
                hører vi også gang på gang fra vores brugere at OnLion er "nemt
                at bruge", "ligetil", "intuitivt" - det er vi meget stolte af.
              </p>
              <p>
                Vi opfordre dig til at prøve OnLion ved at oprette en gratis
                konto. Hvis du er i tvivl står vi klar til at hjælpe, og hvis du
                har foreslag til at gøre OnLion endnu nemmere at bruge hører vi
                meget gerne fra dig.
              </p>
            </div>
          </div>

          {/* FAQ 4 */}

          <div className="faq-sim__single-faq">
            <div
              className="faq-sim__single-faq-button"
              onClick={() => toggleFAQ(4)}
            >
              <div className="group row space-between">
                <span>Er OnLion sikkert at bruge? Og hvad med mine data?</span>

                {!opened[4] && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="faq-sim__accordion-icon icon-cheveron-up-circle"
                  >
                    <circle cx="12" cy="12" r="10" className="primary" />
                    <path
                      className="secondary"
                      d="M8.7 13.7a1 1 0 1 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 10.42l-3.3 3.3z"
                    />
                  </svg>
                )}

                {opened[4] && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="faq-sim__accordion-icon icon-cheveron-up-circle"
                  >
                    <circle cx="12" cy="12" r="10" className="primary" />
                    <path
                      className="secondary"
                      d="M15.3 10.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div
              className={`faq-sim__single-faq-content ${
                opened[4] && "faq-sim__single-faq-content-show"
              }`}
            >
              <label htmlFor="" className="label">
                Sikkerhed: Kryptering i verdensklasse
              </label>
              <p>
                Vi forstår hvor vigtigt det er at din data kun ses af dig, og
                vores sikkerhedsforanstaltninger er taget derefter - OnLion er
                udviklet på førende sikkerhedsprincipper. Vi krypterer dine
                data, så vi lever op til GDPR og vores ansvar som
                databehandlere. Vores kryptering er den samme som er brugt af
                militæret og efterretningstjenester verden over.
              </p>
              <p>
                Det eneste du skal sikre dig er at du vælger et sikkert password
                til din konto. Det vigtigste er at det er langt (vi kræver at
                det er minimum 10 karakterer), svært at gætte, og ikke bliver
                brugt andre steder. Læs eventuelt{" "}
                <a
                  href="https://cfcs.dk/da/forebyggelse/vejledninger/passwords/"
                  target="_blank"
                >
                  vejledningen til et sikkert password
                </a>{" "}
                fra Center for Cybersikkerhed.
              </p>

              <hr />
              <label htmlFor="" className="label">
                Daglige Backup og sikkerhedskopier
              </label>
              <p>
                Vi tager backup af al din data dagligt, og har altid mindst to
                kopier af din data. På den måde er vi sikre på at intet går
                tabt. Det samme gælder de filer (manualer, servicerapporter, med
                mere) som du uploader til OnLion.
              </p>

              <hr />
              <label htmlFor="" className="label">
                Eksport af data
              </label>
              <p>
                Du har altid mulighed for nemt at downloade din OnLion data i
                Excel format. For eksempel hvis du gerne vil have din egen
                backup (udover den vi tager), eller gerne vil skifte til et
                andet system.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ

// FAQS
// Hvad er OnLion og hvordan virker det?
// Hvad koster OnLion?
// Hvad med support? og kan jeg selv bruge OnLion?
// Kan jeg bruge OnLion fra min telefon?
// Hvad med sikkerhed?
