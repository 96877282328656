// If you don't want to use TypeScript you can delete this file!
import * as React from "react"

import "./steps.css"

const Steps = () => (
  <div className="steps-sim__outer">
    <div className="steps-sim__wrapper">
      <div className="steps-sim__steps group row-mobile-col">
        <div>
          <div className="steps-sim__steps-header group">
            <h3>1. Prøv en gratis konto </h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="icon icon-user"
            >
              <path
                className="steps-sim__icon-primary"
                d="M9 11a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
              />
              <path
                className="steps-sim__icon-secondary"
                d="M16 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1zm1-8.41l3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 1 1 1.4-1.42l1.3 1.3z"
              />
            </svg>
          </div>
          <p>
            Du kan komme igang med din egen konto på 2 minutter, kvit og frit.
            Uden tidsbegrænsning. På den måde kan du være helt sikker før du
            vælger.
          </p>
        </div>
        <div>
          <div className="steps-sim__steps-header group">
            <h3>2. Tilføj dit vedligehold</h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="icon icon-calendar-add"
            >
              <path
                className="steps-sim__icon-primary"
                d="M5 4h14a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm0 5v10h14V9H5z"
              />
              <path
                className="steps-sim__icon-secondary"
                d="M13 13h2a1 1 0 0 1 0 2h-2v2a1 1 0 0 1-2 0v-2H9a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2zM7 2a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1zm10 0a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1z"
              />
            </svg>
          </div>
          <p>
            Du angiver hvad der skal vedligeholdes, hvordan, og hvem der skal
            gøre det. OnLion sender automatisk opgaven til den ansvarlige når
            den skal udføres.
          </p>
        </div>
        <div>
          <div className="steps-sim__steps-header group">
            <h3>3. Få komplet overblik</h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="icon icon-trending-up"
            >
              <path
                className="steps-sim__icon-primary"
                d="M3.7 20.7a1 1 0 1 1-1.4-1.4l6-6a1 1 0 0 1 1.4 0l3.3 3.29 4.3-4.3a1 1 0 0 1 1.4 1.42l-5 5a1 1 0 0 1-1.4 0L9 15.4l-5.3 5.3z"
              />
              <path
                className="steps-sim__icon-secondary"
                d="M16.59 8l-2.3-2.3A1 1 0 0 1 15 4h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1.7.7L18 9.42l-4.3 4.3a1 1 0 0 1-1.4 0L9 10.4l-5.3 5.3a1 1 0 1 1-1.4-1.42l6-6a1 1 0 0 1 1.4 0l3.3 3.3L16.59 8z"
              />
            </svg>
          </div>

          <p>
            Du kan til hver tid se om vedligehold bliver udført. Vi sender dig
            endda en opsumering i slutningen af måneden. Det er overblik.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Steps
